button,
input[type="button"],
input[type="submit"] {
  padding: 6px 10px 6px 10px;
  border: 1px solid #8ba0bc;
  font-weight: bold;
  color: #384e73;
  font-family: "lucida grande", Helvetica, Arial, Tahoma, Verdana, Tahoma, Sans-Serif;
  font-size: 12px;
  background: #bccadd url("/images/gradient-blue.png") repeat-x 0 center;
}

button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  background: white;
  cursor: pointer;
}

button:active,
input[type="button"]:active,
input[type="submit"]:active {
  padding: 7px 9px 5px 11px;
}

input[type="text"],
textarea,
input[type="textarea"],
input[type="select"],
select,
.ui-autocomplete-input,
input[type="password"] {
  font-family: inherit;
  padding: 4px;
  width: 100%;
  color: #384e73;
  border: 1px solid #b4bbcd;
  font-size: 13px;
  resize: none;
}

input[type="text"]:hover,
textarea:hover,
input[type="textarea"]:hover,
.ui-autocomplete-input:hover,
input[type="password"]:hover {
  border: 1px solid #8ba0bc;
}

input[type="text"]:focus,
textarea:focus,
input[type="textarea"]:focus,
.ui-autocomplete-input:focus,
input[type="password"]:hover {
  border: 1px solid #8ba0bc;
}
