html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}
.header,
.body,
.footer {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.header_content,
.body_content,
.footer_content {
  vertical-align: middle;
  position: relative;
  margin: 0 auto;
  height: 100%;
}
