/* site basics */
@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  color: #444444;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  background-image: url(/images/building-clouds.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flex-row.center {
  align-items: center;
}

.flex-row.space-between {
  justify-content: space-between;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.header {
  height: 75px;
  border-bottom: 1px solid #999999;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 2;
}
.footer {
  background: #adb1bb;
}
.body {
  min-height: 545px;
  z-index: 2;
}
.body_content {
  padding: 15px;
}
.body,
.footer_content,
.header_content {
  min-width: 320px;
  max-width: 980px;
}

.footer_content {
  padding-top: 12px;
}

.transparent50 {
  /* Theoretically for IE 8 & 9 (more valid) */ /* ...but not required as filter works too */ /* should come BEFORE filter */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* This works in IE 8 & 9 too */ /* ... but also 5, 6, 7 */
  filter: alpha(opacity=50); /* Older than Firefox 0.9 */
  -moz-opacity: 0.5; /* Safari 1.x (pre WebKit!) */
  -khtml-opacity: 0.5; /* Modern!
	/* Firefox 0.9+, Safari 2?, Chrome any?
	/* Opera 9+, IE 9+ */
  opacity: 0.5;
}

p {
  line-height: 1.5em;
  padding: 0 5px;
}
p.bigger {
  font-size: 115%;
}

a {
  color: #444444;
  text-decoration: underline;
  font-weight: normal;
}
a:hover {
  color: #777777;
  text-decoration: underline;
}
a:active {
  color: #777777;
  text-decoration: underline;
}

h1 {
  font-size: 1.8em;
  font-weight: normal;
  margin-bottom: 5px;
}
h2 {
  font-weight: bold;
  margin-bottom: 5px;
}
h3 {
  font-size: 1.4em;
  font-weight: normal;
  margin-bottom: 8px;
}
h4 {
  font-weight: bold;
}

img.logo {
  height: 47px;
}

/* content sections */
.content_section {
  border: 1px solid #b4bbcd;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  -moz-box-shadow: 0 0 5px #999;
  margin: 7px 0 7px 0;
  float: left;
  width: 92%;
}

.content_section_container {
  float: left;
}

@media only screen and (max-width: 651px) {
  .content_section_container {
    width: 100%;
  }
  h2 {
    font-size: 1.3em;
  }
  p.bigger {
    font-size: 106%;
  }
  h4 {
    font-size: 1.1em;
  }
  .content_section li {
    font-size: 97%;
  }
  .content_section li {
    padding: 3px 0;
  }
}

@media only screen and (min-width: 652px) {
  .content_section_container {
    width: 50%;
  }
  h2 {
    font-size: 1.7em;
  }
  p.bigger {
    font-size: 110%;
  }
  h4 {
    font-size: 1.1em;
  }
  .content_section li {
    font-size: 100%;
  }
  .content_section li {
    padding: 4px 0;
  }
}

.content_section ul {
  list-style: none;
  margin: 0;
}

/* fades */
.white_gray_fade {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#cccccc));
  background-image: -webkit-linear-gradient(#ffffff, #cccccc);
  background-image: -moz-linear-gradient(#ffffff, #cccccc);
  background-image: -ms-linear-gradient(#ffffff, #cccccc);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#cccccc',GradientType=0);
  background-image: -o-linear-gradient(#ffffff, #cccccc);
  background-image: linear-gradient(#ffffff, #cccccc);
}

.gray_white_fade {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cccccc), to(#ffffff));
  background-image: -webkit-linear-gradient(#cccccc, #ffffff);
  background-image: -moz-linear-gradient(#cccccc, #ffffff);
  background-image: -ms-linear-gradient(#cccccc, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cccccc',endColorstr='#ffffff',GradientType=0);
  background-image: -o-linear-gradient(#cccccc, #ffffff);
  background-image: linear-gradient(#cccccc, #ffffff);
}

/* header specific */
.header_content h1 {
  font-size: 1.8em;
  font-weight: normal;
  margin-bottom: 5px;
}
.header_content h2 {
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 8px;
}
.header_content h3 {
  font-size: 1.4em;
  font-weight: normal;
  margin-bottom: 8px;
}
.header_content a {
  font-size: 110%;
  text-decoration: none;
}
.header_content a:hover {
  text-decoration: underline;
}

/* footer specific */
.footer_content {
  color: #666;
  font-size: 12px;
  text-align: center;
}
.footer_content a {
  color: #666;
  padding: 2px;
  text-decoration: none;
}
.footer_content a:hover {
  text-decoration: underline;
}
.footer_content p {
  padding: 2px;
}

#particles-js {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.particles-js-canvas-el {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
