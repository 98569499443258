/* common */
.menu {
  padding: 0px;
  float: left;
  position: relative;
}
.menu .items {
  position: relative;
  bottom: 0px;
  right: 0px;
  float: right;
}
.menu .item {
  cursor: pointer;
  margin-left: 5px;
  text-align: center;
  padding: 2px;
  position: relative;
  bottom: 0px;
  z-index: 99;
}

.menu .title {
  font-size: 115%;
  font-weight: bold;
  float: left;
}
.menu.horizontal .item,
.menu.horizontal .title {
  display: inline-block;
  vertical-align: middle;
}

/* theming */
.menu.themed.A {
}
.menu.themed.A .item {
  border: 1px solid transparent;
  margin: 0px;
  font-weight: bold;
  text-decoration: none;
}
.menu.themed.A .item:hover {
  color: black;
  background: transparent;
  border: 1px solid #b4bbcd;
}
.menu.themed.A .title {
  font-size: 1.1em;
  color: #444444;
  margin-right: 15px;
  padding-top: 10px;
}
.menu.themed.A .item.selected {
  color: black;
  background: transparent;
  border: 1px solid #b4bbcd;
}

@media only screen and (max-width: 651px) {
  .menu.themed.A .item {
    padding: 6px 6px 5px 6px;
    font-size: 98%;
  }
}

@media only screen and (min-width: 652px) {
  .menu.themed.A .item {
    padding: 8px 8px 7px 8px;
    font-size: 100%;
  }
}
