/* reset */
html {
  margin: 0;
  padding: 0;
  border: 0;
}
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: top;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
body {
  background: white;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
table,
th,
tbody,
tr,
td,
ul,
li {
  vertical-align: inherit;
  font-family: inherit;
  font-size: inherit;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: "" "";
}
a img {
  border: none;
}
:focus {
  outline: 0;
}

/* basic typography */
html {
  font-size: 100%;
  width: 100%;
  height: 100%;
}
body {
  font-size: 100%;
  width: 100%;
  height: 100%;
  color: #222;
  background: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  padding-bottom: 2px;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.5em;
}
h4,
legend {
  font-size: 1.2em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  color: #555555;
}
h6 {
  font-size: 1em;
}
fieldset {
  border: 1px solid #aaaaaa;
  padding: 5px;
}
legend {
  padding: 4px;
}
table td {
  padding: 0px;
}
